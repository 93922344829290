import { fromJS, Map } from "immutable";

import { normalize } from "Libs/utils";
import logger from "Libs/logger";

const LOAD_ROUTES_START = "app/routes/load_routes_start";
const LOAD_ROUTES_SUCCESS = "app/routes/load_routes_success";
const LOAD_ROUTES_FAILURE = "app/routes/load_routes_failure";

const UPDATE_ROUTE_START = "app/route/update_route_start";
const UPDATE_ROUTE_SUCCESS = "app/route/update_route_success";
const UPDATE_ROUTE_FAILURE = "app/route/update_route_failure";
const UPDATE_ROUTE_CANCEL = "app/route/update_variable_cancel";

const DELETE_ROUTE_START = "app/route/delete_route_start";
const DELETE_ROUTE_SUCCESS = "app/route/delete_route_success";
const DELETE_ROUTE_FAILURE = "app/route/delete_route_failure";

const EDIT_LINE = "app/route/edit_line";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const cancelUpdateRoute = () => ({ type: UPDATE_ROUTE_CANCEL });

export const deleteRoute = route => {
  return async dispatch => {
    if (!route) {
      return false;
    }

    dispatch({ type: DELETE_ROUTE_START });

    try {
      await route.delete();
      dispatch({
        type: DELETE_ROUTE_SUCCESS,
        payload: route
      });
    } catch (err) {
      logger(err, {
        action: "delete_route",
        payload: route
      });
      dispatch({ type: DELETE_ROUTE_FAILURE, error: true, payload: err });
    }
  };
};

export const loadRoutes = (
  organizationId,
  projectId,
  environmentId,
  environment
) => {
  return async dispatch => {
    if (!environment) {
      return false;
    }

    dispatch({ type: LOAD_ROUTES_START });

    try {
      const routes = await environment.getRoutes();

      dispatch({
        type: LOAD_ROUTES_SUCCESS,
        payload: {
          routes
        },
        meta: { organizationId, projectId, environmentId }
      });
    } catch (err) {
      logger(err, {
        action: "load_route",
        meta: { organizationId, projectId, environmentId }
      });
      dispatch({ type: LOAD_ROUTES_FAILURE, error: true, payload: err });
    }
  };
};

export const updateRoute = (environment, route, editedLine, isNew) => {
  return async dispatch => {
    if (!environment) {
      return false;
    }
    dispatch({ type: UPDATE_ROUTE_START });

    try {
      const result = await environment.setRoute({
        ...route,
        id: route.id ? encodeURIComponent(route.id) : undefined
      });

      if ((result.data && result.data.code / 100) === 4) {
        return dispatch({
          type: UPDATE_ROUTE_FAILURE,
          payload: result.detail,
          meta: { editedLine, isNew }
        });
      }

      const newRoute = await result.getEntity();

      dispatch({
        type: UPDATE_ROUTE_SUCCESS,
        payload: newRoute,
        meta: { environmentId: environment.id }
      });
    } catch (err) {
      logger(err, {
        action: "update_route",
        environment,
        route,
        editedLine,
        isNew,
        meta: { environmentId: environment.id }
      });
      dispatch({ type: UPDATE_ROUTE_FAILURE, payload: err });
    }
  };
};

export default function routeReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ROUTE_START:
      return state.set("updateLoading", true);
    case LOAD_ROUTES_START:
      return state.set("loading", true);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case UPDATE_ROUTE_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case DELETE_ROUTE_SUCCESS:
      return state.deleteIn([
        "data",
        action.payload.environment,
        action.payload.id
      ]);
    case UPDATE_ROUTE_SUCCESS:
      return state
        .setIn(
          ["data", action.payload.environment, action.payload.id],
          fromJS(action.payload)
        )
        .set("editedLine", false)
        .set("isNew", false)
        .set("updateLoading", false);
    case LOAD_ROUTES_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationId,
            action.meta.projectId,
            action.meta.environmentId
          ],
          fromJS(normalize(action.payload.routes))
        );
    case UPDATE_ROUTE_FAILURE:
      return state
        .set("loading", false)
        .set("errors", action.payload)
        .set("updateLoading", false);
    case LOAD_ROUTES_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    default:
      return state;
  }
}
